// Outer
import React from 'react';
import {Box} from '@material-ui/core';

interface DefaultLayoutProps {
  children: React.ReactNode;
}

export default function DefaultLayout({children}: DefaultLayoutProps) {
  return <Box margin={2}>{children}</Box>;
}
