// Outer
import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { alpha, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  IconButton,
  TextField,
  Backdrop,
  CircularProgress,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  GiHamburgerMenu,
} from 'react-icons/gi';
import { FaHashtag, FaUserPlus, FaRegAddressBook, FaMoneyBillAlt } from 'react-icons/fa';
import {
  VscRepo,
  VscRepoPull,
  VscRepoPush,
  VscHome,
  VscVerified,
  VscUnverified,
  VscSettingsGear,
  VscSmiley,
  VscLiveShare,
  VscCloudUpload,
  VscEdit,
  VscRocket,
  VscDashboard,
  VscLibrary,
} from 'react-icons/vsc';
import { FiUsers } from 'react-icons/fi';
import { BsFillPersonLinesFill } from 'react-icons/bs';
import { AiOutlineSearch } from 'react-icons/ai';
import { BiLogOutCircle, BiLogInCircle } from 'react-icons/bi';
import {
  RiAccountBoxFill,
  RiHandCoinLine,
  RiDashboard3Line,
  RiShoppingCart2Line,
  RiShoppingBasketLine,
} from 'react-icons/ri';
import { VscSettings, VscGraph } from 'react-icons/vsc';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import {
  Box,
  Tab,
  Tabs,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { useRouter } from 'next/router';
import { useQuery } from 'react-query';

// Inner
import useQueryParams from '@/common/hooks/useQueryParams';
import SimpleDrawer from '@/common/components/SimpleDrawer';
import AdminBadge from '@/common/components/AdminBadge';
import UserBadge from '@/common/components/UserBadge';
import Badge from '@material-ui/core/Badge';
import { appRoutes, resolveImageUrl } from '@/common/config';
import { queries } from '@/common/api/operations';
import { useUserContext } from '@/common/contexts/UserContext';
import { useApiContext } from '@/common/contexts/ApiContext';
import { useSearchListingsAndHashtagsAutocomplete } from '@/admin/features/autocomplete/useSearchListingsAndHashtagsAutocomplete';
import { useGlobalContext } from '@/common/contexts/GlobalContext';

const useStyles = makeStyles<Theme, HeaderBarProps>(theme => ({
  customTabsStyle: {
    '& .MuiTabScrollButton-root': {
      color: theme.palette.text.primary,
    },
  },
  tab: {
    textTransform: 'none',
  },
  appBar: {
    backgroundColor: theme.palette.background.paper,
  },
  menuButton: {
    color: theme.palette.text.primary,
    marginRight: theme.spacing(2),
  },
  title: {
    cursor: 'pointer',
    color: theme.palette.text.primary,
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    [theme.breakpoints.down('sm')]: {
      display: ({ hideSearch }) => (hideSearch ? 'block' : 'none'),
    },
  },
  autocomplete: {
    width: '100%',
    margin: theme.spacing(1),
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.2),
    },
    display: 'flex',
    alignContent: 'center',
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(4),
      width: 'auto',
    },
  },
  searchIcon: {
    color: theme.palette.text.primary,
    padding: theme.spacing(0, 1),
    // height: '100%',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputInput: {
    // vertical padding + font size from searchIcon
    // marginLeft: `calc(1em + ${theme.spacing(2)}px)`,
    // transition: theme.transitions.create('width'),
    '& .MuiFilledInput-root': {
      background: alpha(theme.palette.common.white, 0),
    },
    '& .MuiInputBase-inputTypeSearch': {
      "WebkitAppearance": 'none',
    }
  },
  listingImage: {
    width: '48px',
    height: '48px',
    borderRadius: '50%',
  },
  badge: {
    fontSize: 10
  },
  customBadge: {
    '& .MuiBadge-badge': {
      padding: 0
    }
  },
  colorWarning: {
    '& .MuiBadge-badge': {
      background: '#CD8A07',
    }
  },
  compayProfilesHolder: {
    paddingTop: '15px',
    paddingBottom: '5px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '200px',
      paddingTop: '0',
      marginRight: '20px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '300px',
    },
  },
  barHolder: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }
}));

const planList = {
  one: 'rightsmage basic',
  two: 'rightsmage pro',
  three: 'rightsmage small press',
  four: 'rightsmage publisher',
}

interface HeaderBarProps {
  hideSearch?: boolean;
  hideMenu?: boolean;
}

const useUserDelegation = (list: Array<any>, settingsList: Array<any>, email: string, userId: string) => {
  if (!list?.length || !settingsList?.length) return [];

  const userCompanySetting = settingsList.filter((item) => item.userId === userId);

  const initDefaultProfile = {
    companyName: userCompanySetting?.[0]?.companyName ?? 'Default Profile',
    userId: '',
    id: Date.now(),
  };
  const delegation = list?.filter((item: any) => item.email === email);
  const prepareDelegation = delegation.map((item: any) => {
    const companySetting = settingsList.find((i: any) => i.id === item.companySettingsId);

    return {
      ...item,
      companyName: companySetting.companyName || 'No Name',
    }
  });

  if (prepareDelegation && prepareDelegation?.length === 0) {
    return []
  }

  return initDefaultProfile ? [initDefaultProfile, ...prepareDelegation] : prepareDelegation;
}

export default function HeaderBar({
  hideMenu = false,
  hideSearch = false,
}: HeaderBarProps) {
  const { siteName, siteLogoURL } = useGlobalContext()
  const classes = useStyles({ hideSearch });
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const toggleDrawer = React.useCallback(() => {
    setIsDrawerOpen(val => !val);
  }, []);
  const {
    isSearching,
    isSearchingOptions,
    searchText,
    autocompleteResults,
    handleSearchTextChange,
    handleSelectText,
  } = useSearchListingsAndHashtagsAutocomplete();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { push } = useRouter();
  const { categoryName } = useQueryParams();
  const { apiClient } = useApiContext();
  const result = useQuery(queries.search.menuItems, apiClient.search.menuItems);
  const menuItems = result.data?.menuItems ?? [];
  const handleTabChange = (e: React.ChangeEvent<{}>, tabIndex: number) => {
    const item = menuItems[tabIndex];
    push(appRoutes.home.category(item.name));
  };
  const tabIndex = menuItems.findIndex(item => item.name === categoryName);
  const {
    user,
    isLoggedIn,
    listingId,
    isAdmin,
    logout,
    setUserId,
    isDelegatedSubscription,
  } = useUserContext();
  const email = user?.email ?? '';
  const userId = user?.id ?? '';

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const delegatedId = user?.delegatedId || '';
  const isSubscriptionValue = (isDelegatedSubscription === null) ? !!user?.isSubscription : isDelegatedSubscription;
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const countMissingEmailRightsholder = user?.countMissingEmailRightsholder || 0;
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const countMissingProperty = user?.countMissingProperty || 0;
  const isDelinquent = user?.isDelinquent;
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const isCompanySettings = user?.isCompanySettings || 0;
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const plan = user?.plan?.toLowerCase() || '';

  const [currentProfile, setCurrentProfile] = useState(delegatedId);
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState({
    admin: true,
    rightsmage: false,
  });

  const toggleMenu = (key: string) => {
    setIsMenuOpen(() => {
      const initState = {
        admin: false,
        rightsmage: false
      }
      return {
        ...initState,
        [key]: true,
      }
    })
  }

  const companySettingsDelegationQuery = useQuery(
    queries.companysettingsdelegation.get,
    () => apiClient.companysettingsdelegation.get()
  );
  const allDelegation = companySettingsDelegationQuery.data;

  const companySettingsQuery = useQuery(
    queries.companySettings.get,
    () => apiClient.companySettings.get()
  );
  const allCompanySettings = companySettingsQuery.data;
  const currentDelegation = useUserDelegation(allDelegation, allCompanySettings, email, userId);

  useEffect(() => {
    if (!isAdmin && currentDelegation?.length > 0 && currentDelegation[0].userId) {
      setCurrentProfile(currentDelegation[0].userId);
      setUserId(currentDelegation[0].userId);
    }
  }, [email]);

  const handleProfiles = (e: any) => {
    const target = e.target;

    setCurrentProfile(target.value);
    setUserId(target.value);

    if (!isAdmin && target.value === '') {
      push(appRoutes.home.root);
    }
  }


  return (
    <>
      <AppBar className={classes.appBar} position="static">
        <Toolbar>
          <Backdrop style={{ color: '#fff', zIndex: 100 }} open={isSearching}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Box className={classes.barHolder} width="100%" pt={1}>
            <Box display="flex" alignItems="center">
              <Box display="flex" alignItems="center">
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleDrawer}
                >
                  <GiHamburgerMenu />
                </IconButton>

                <Link href={appRoutes.home.root}>
                  <Typography className={classes.title} variant="h6" noWrap>
                    {siteName}
                  </Typography>
                </Link>
              </Box>
              {!hideSearch && (
                <Box display="flex" alignItems="center">
                  <div className={classes.search}>
                    <div className={classes.searchIcon}>
                      <AiOutlineSearch size={20} />
                    </div>

                    <Autocomplete
                      open={isAutocompleteOpen}
                      freeSolo
                      disableClearable
                      id="search-autocomplete"
                      options={autocompleteResults}
                      style={{ width: 180 }}
                      onOpen={() => {
                        if (searchText) {
                          setIsAutocompleteOpen(true);
                        }
                      }}
                      onClose={() => setIsAutocompleteOpen(false)}
                      loading={isSearchingOptions}
                      onChange={handleSelectText}
                      inputValue={searchText}
                      onInputChange={handleSearchTextChange}
                      renderInput={params => (
                        <TextField
                          {...params}
                          size="small"
                          label="Search..."
                          variant="filled"
                          className={classes.inputInput}
                          InputProps={{
                            ...params.InputProps,
                            type: 'search',
                            disableUnderline: true,
                            endAdornment: (
                              <React.Fragment>
                                {isSearchingOptions && (
                                  <CircularProgress color="inherit" size={20} />
                                )}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </div>
                </Box>
              )}
            </Box>

            <Box display="flex" alignItems="center">
              {/* Select Delegated Profile */}
              {isLoggedIn && currentDelegation?.length > 0 && (
                <Box
                  display="flex"
                  alignItems="center"
                  className={classes.compayProfilesHolder}
                >
                  <FormControl variant="filled" fullWidth>
                    <InputLabel id="currentProfile">Profiles</InputLabel>
                    <Select
                      labelId="currentProfile"
                      id="currentProfile"
                      name="currentProfile"
                      value={currentProfile}
                      fullWidth
                      onChange={handleProfiles}
                      variant="outlined"
                    >
                      {currentDelegation?.map((item: any) => (
                        <MenuItem key={item.userId} value={item.userId}>
                          {item.companyName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              )}

              {/* users */}
              {isDesktop && isLoggedIn && !isAdmin && (
                <Box display="flex" alignItems="center">
                  <UserBadge user={user} listingId={listingId} />

                  <Box display="flex" flexDirection="column" marginLeft={1}>
                    <Typography variant="subtitle2" color="textPrimary" onClick={() => { push(appRoutes.account.listing(listingId ?? 'new')); }} style={{ cursor: "pointer" }}>
                      {user?.name}
                    </Typography>
                    <Typography variant="caption" color="textPrimary" onClick={() => { push(appRoutes.account.listing(listingId ?? 'new')); }} style={{ cursor: "pointer" }}>
                      {user?.email}
                    </Typography>
                  </Box>
                </Box>
              )}

              {/* admins */}
              {isDesktop && isLoggedIn && isAdmin && (
                <Box display="flex" alignItems="center">
                  <Box display="flex" alignItems="center">
                    {user?.listingImageId ? (
                      <img
                        src={resolveImageUrl(user.listingImageId)}
                        className={classes.listingImage}
                      />
                    ) : (
                      <AdminBadge />
                    )}

                    <Box display="flex" flexDirection="column" marginLeft={1}>
                      <Typography variant="caption" color="textPrimary">
                        {user?.name}
                      </Typography>
                      <Typography variant="caption" color="textPrimary">
                        {user?.email}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Toolbar>
        {!hideMenu && (
          <Tabs
            value={tabIndex === -1 ? false : tabIndex}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="on"
            classes={{ root: classes.customTabsStyle }}
          >
            {menuItems.map(item => (
              <Tab
                key={item.id}
                label={item.name}
                classes={{ root: classes.tab }}
              />
            ))}
          </Tabs>
        )}
      </AppBar>

      <SimpleDrawer
        anchor="left"
        strictClose={false}
        isOpen={isDrawerOpen}
        onClose={toggleDrawer}
        fullMobileWidth={false}
      >
        {isLoggedIn && (
          <>
            <List>

              {/* users */}
              {!isAdmin && (
                <ListItem button>
                  <UserBadge size={'large'} user={user} listingId={listingId} />

                  <Box display="flex" flexDirection="column" marginLeft={1}>
                    <Typography variant="subtitle2">{user?.name}</Typography>
                    <Typography variant="caption">{user?.email}</Typography>
                  </Box>
                </ListItem>
              )}

              {/* admins */}
              {isAdmin && (
                <ListItem button>
                  {user?.listingImageId ? (
                    <img
                      src={resolveImageUrl(user.listingImageId)}
                      className={classes.listingImage}
                    />
                  ) : (
                    <AdminBadge />
                  )}
                  <Box display="flex" flexDirection="column" marginLeft={1}>
                    <Typography variant="subtitle2">{user?.name}</Typography>
                    <Typography variant="caption">{user?.email}</Typography>
                  </Box>
                </ListItem>
              )}
              {/* <List>
                <ListItem
                  button
                  onClick={() => {
                    push(appRoutes.home.root);
                    toggleDrawer();
                  }}
                >
                  <ListItemIcon>
                    <VscHome size={24} color={theme.palette.primary.main} />
                  </ListItemIcon>
                  <ListItemText primary="Home" />
                </ListItem>
                {isLoggedIn && (
                  <ListItem
                    button
                    onClick={() => {
                      push(appRoutes.admin.subscriptions);
                      toggleDrawer();
                    }}
                  >
                    <ListItemIcon>
                      <VscRocket size={24} color={theme.palette.success.main} />
                    </ListItemIcon>
                    <ListItemText primary="Subscriptions" />
                  </ListItem>
                )}
              </List>
              <Divider /> */}
              {!isAdmin && (
                <>
                  <Divider />
                  <ListItem
                    button
                    onClick={toggleMenu.bind(null, 'admin')}
                  >
                    <ListItemText primary="BookMage" />
                    {
                      isMenuOpen.admin ? (
                        <TiArrowSortedDown>
                          <VscSmiley
                            size={24}
                            color={theme.palette.success.light}
                          />
                        </TiArrowSortedDown>
                      ) : (
                        <TiArrowSortedUp>
                          <VscSmiley
                            size={24}
                            color={theme.palette.success.light}
                          />
                        </TiArrowSortedUp>
                      )
                    }
                  </ListItem>

                  {
                    isMenuOpen.admin && (
                      <>
                        <ListItem
                          button
                          onClick={() => {
                            push(appRoutes.home.root);
                            toggleDrawer();
                          }}
                        >
                          <ListItemIcon>
                            <VscHome size={24} color='white' />
                          </ListItemIcon>
                          <ListItemText primary="Home" />
                        </ListItem>

                        <ListItem
                          button
                          onClick={() => {
                            push(appRoutes.account.listing(listingId ?? 'new'));
                            toggleDrawer();
                          }}
                        >
                          <ListItemIcon>
                            <RiAccountBoxFill
                              size={24}
                              color={theme.palette.primary.main}
                            />
                          </ListItemIcon>
                          <ListItemText primary="My Account" />
                        </ListItem>
                        {isLoggedIn && (
                          <ListItem
                            button
                            onClick={() => {
                              push(appRoutes.admin.subscriptions);
                              toggleDrawer();
                            }}
                          >
                            <ListItemIcon>
                              <Badge badgeContent={isDelinquent ? 'Unpaid' : 0} color={'error'} showZero={false} overlap="circular" anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                                <VscRocket size={24} color={theme.palette.success.main} />
                              </Badge>
                            </ListItemIcon>
                            <ListItemText primary="Subscriptions" />
                          </ListItem>
                        )}
                      </>)
                  }

                </>
              )}

            </List>
            <Divider />
          </>
        )}
        {isAdmin && (
          <>
            <List>
              <ListItem
                button
                onClick={toggleMenu.bind(null, 'admin')}
              >
                <ListItemText primary="Admin" />
                {
                  isMenuOpen.admin ? (
                    <TiArrowSortedDown>
                      <VscSmiley
                        size={24}
                        color={theme.palette.success.light}
                      />
                    </TiArrowSortedDown>
                  ) : (
                    <TiArrowSortedUp>
                      <VscSmiley
                        size={24}
                        color={theme.palette.success.light}
                      />
                    </TiArrowSortedUp>
                  )
                }
              </ListItem>

              {
                isMenuOpen.admin && (
                  <>
                    <ListItem
                      button
                      onClick={() => {
                        push(appRoutes.admin.users());
                        toggleDrawer();
                      }}
                    >
                      <ListItemIcon>
                        <FiUsers size={24} color={theme.palette.primary.main} />
                      </ListItemIcon>
                      <ListItemText primary="Users" />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        push(appRoutes.admin.siteSettings());
                        toggleDrawer();
                      }}
                    >
                      <ListItemIcon>
                        <VscSettingsGear size={24} color={theme.palette.warning.main} />
                      </ListItemIcon>
                      <ListItemText primary="Site Settings" />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        push(appRoutes.admin.listings());
                        toggleDrawer();
                      }}
                    >
                      <ListItemIcon>
                        <VscSmiley
                          size={24}
                          color={theme.palette.success.light}
                        />
                      </ListItemIcon>
                      <ListItemText primary="Listings" />
                    </ListItem>

                    <ListItem
                      button
                      onClick={() => {
                        push(appRoutes.admin.hashtags());
                        toggleDrawer();
                      }}
                    >
                      <ListItemIcon>
                        <FaHashtag size={24} color={theme.palette.primary.main} />
                      </ListItemIcon>
                      <ListItemText primary="Hashtags" />
                    </ListItem>

                    <ListItem
                      button
                      onClick={() => {
                        push(appRoutes.admin.badges());
                        toggleDrawer();
                      }}
                    >
                      <ListItemIcon>
                        <VscVerified size={24} color={theme.palette.success.light} />
                      </ListItemIcon>
                      <ListItemText primary="Badges" />
                    </ListItem>

                    <ListItem
                      button
                      onClick={() => {
                        toggleDrawer();
                        push(appRoutes.admin.badgeRequests());
                      }}
                    >
                      <ListItemIcon>
                        <VscUnverified size={24} color={theme.palette.warning.main} />
                      </ListItemIcon>
                      <ListItemText primary="Badge Requests" />
                    </ListItem>

                    <ListItem
                      button
                      onClick={() => {
                        push(appRoutes.admin.subscriptions);
                        toggleDrawer();
                      }}
                    >
                      <ListItemIcon>
                        <Badge badgeContent={isDelinquent ? 'Unpaid' : 0} color={'error'} showZero={false} overlap="circular" anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                          <VscRocket size={24} color={theme.palette.success.main} />
                        </Badge>
                      </ListItemIcon>
                      <ListItemText primary="Subscriptions" />
                    </ListItem>
                  </>
                )
              }
            </List>
            <Divider />
          </>
        )}

        {isSubscriptionValue && (
          <>
            <List>
              <ListItem
                button
                onClick={toggleMenu.bind(null, 'rightsmage')}
              >
                <ListItemText primary="RightsMage" />
                {
                  isMenuOpen.rightsmage ? (
                    <TiArrowSortedDown>
                      <VscSmiley
                        size={24}
                        color={theme.palette.success.light}
                      />
                    </TiArrowSortedDown>
                  ) : (
                    <TiArrowSortedUp>
                      <VscSmiley
                        size={24}
                        color={theme.palette.success.light}
                      />
                    </TiArrowSortedUp>
                  )
                }
              </ListItem>

              {
                isSubscriptionValue && isMenuOpen.rightsmage && (
                  <>
                    <ListItem
                      button
                      onClick={() => {
                        push(appRoutes.admin.overviewDashboard);
                        toggleDrawer();
                      }}
                    >
                      <ListItemIcon>
                        <VscGraph size={24} color="magenta" />
                      </ListItemIcon>
                      <ListItemText primary="Overview Dashboard" />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        push(appRoutes.admin.propertyDashboard);
                        toggleDrawer();
                      }}
                    >
                      <ListItemIcon>
                        <VscLibrary size={24} color={theme.palette.primary.main} />
                      </ListItemIcon>
                      <ListItemText primary="Property Dashboard" />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        push(appRoutes.admin.rightsholderDashboard);
                        toggleDrawer();
                      }}
                    >
                      <ListItemIcon>
                        <BsFillPersonLinesFill size={24} color={theme.palette.success.main} />
                      </ListItemIcon>
                      <ListItemText primary="Rightsholder Dashboard" />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        push(appRoutes.admin.channelDashboard);
                        toggleDrawer();
                      }}
                    >
                      <ListItemIcon>
                        <RiShoppingCart2Line size={24} color="magenta" />
                      </ListItemIcon>
                      <ListItemText primary="Channel Dashboard" />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        push(appRoutes.admin.retailerDashboard);
                        toggleDrawer();
                      }}
                    >
                      <ListItemIcon>
                        <RiShoppingBasketLine size={24} color={theme.palette.primary.main} />
                      </ListItemIcon>
                      <ListItemText primary="Retailer Dashboard" />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        push(appRoutes.static.filesUpload);
                        toggleDrawer();
                      }}
                    >
                      <ListItemIcon>
                        <VscCloudUpload size={24} color={theme.palette.success.main} />
                      </ListItemIcon>
                      <ListItemText primary="Import Reports" />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        push(appRoutes.admin.manualsalesentry);
                        toggleDrawer();
                      }}
                    >
                      <ListItemIcon>
                        <VscEdit size={24} color={theme.palette.error.main} />
                      </ListItemIcon>
                      <ListItemText primary="Manual Sales Entry" />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        push(appRoutes.admin.rightsholders);
                        toggleDrawer();
                      }}
                    >
                      <ListItemIcon>
                        <Badge
                          badgeContent={countMissingEmailRightsholder}
                          showZero={false}
                          overlap="circular"
                          anchorOrigin={{ vertical: "top", horizontal: "right" }}
                          classes={{ root: classes.colorWarning }}
                        >
                          <FaRegAddressBook size={24} color={theme.palette.success.main} />
                        </Badge>
                      </ListItemIcon>
                      <ListItemText primary="Rightsholders" />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        push(appRoutes.admin.properties());
                        toggleDrawer();
                      }}
                    >
                      <ListItemIcon>
                        <Badge badgeContent={countMissingProperty} color="error" overlap="circular" anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                          <VscRepo size={24} color={theme.palette.info.main} />
                        </Badge>
                      </ListItemIcon>
                      <ListItemText primary="Properties" />
                    </ListItem>


                    <ListItem
                      button
                      onClick={() => {
                        push(appRoutes.admin.balances);
                        toggleDrawer();
                      }}
                    >
                      <ListItemIcon>
                        <VscLiveShare size={24} color="magenta" />
                      </ListItemIcon>
                      <ListItemText primary="Balances" />
                    </ListItem>

                    <ListItem
                      button
                      onClick={() => {
                        push(appRoutes.admin.payments);
                        toggleDrawer();
                      }}
                    >
                      <ListItemIcon>
                        <FaMoneyBillAlt size={24} color={theme.palette.success.main} />
                      </ListItemIcon>
                      <ListItemText primary="Payments" />
                    </ListItem>

                    <ListItem
                      button
                      onClick={() => {
                        push(appRoutes.admin.expenses);
                        toggleDrawer();
                      }}
                    >
                      <ListItemIcon>
                        <VscRepoPush size={24} color={theme.palette.warning.main} />
                      </ListItemIcon>
                      <ListItemText primary="Expenses" />
                    </ListItem>
                    {
                      // RightsMage Basic
                      [planList.four, planList.three, planList.two, planList.one].includes(plan) && (
                        <>
                          {/* Should be links for: RightsMage Basic */}
                        </>
                      )
                    }
                    {
                      // RightsMage Pro
                      [planList.four, planList.three, planList.two].includes(plan) && (
                        <>
                          {/* Should be links for: RightsMage Pro */}
                        </>
                      )
                    }
                    {
                      // RightsMage Small Press
                      [planList.four, planList.three].includes(plan) && (
                        <>
                          {/* Should be links for: RightsMage Small Press */}
                        </>
                      )
                    }
                    { // RightsMage Publisher
                      [planList.four].includes(plan) && (
                        <>
                          <ListItem
                            button
                            onClick={() => {
                              push(appRoutes.admin.advances);
                              toggleDrawer();
                            }}
                          >
                            <ListItemIcon>
                              <VscRepoPull size={24} color={theme.palette.error.main} />
                            </ListItemIcon>
                            <ListItemText primary="Advances" />
                          </ListItem>
                          <ListItem
                            button
                            onClick={() => {
                              push(appRoutes.admin.taxes);
                              toggleDrawer();
                            }}
                          >
                            <ListItemIcon>
                              <RiHandCoinLine size={24} color={theme.palette.warning.main} />
                            </ListItemIcon>
                            <ListItemText primary="Tax Rates" />
                          </ListItem>
                          <ListItem
                            button
                            onClick={() => {
                              push(appRoutes.admin.marketingrates);
                              toggleDrawer();
                            }}
                          >
                            <ListItemIcon>
                              <VscDashboard size={24} color={theme.palette.primary.main} />
                            </ListItemIcon>
                            <ListItemText primary="Marketing Rates" />
                          </ListItem>
                        </>
                      )
                    }
                    <ListItem
                      button
                      onClick={() => {
                        push(appRoutes.admin.advances);
                        toggleDrawer();
                      }}
                    >
                      <ListItemIcon>
                        <VscRepoPull size={24} color={theme.palette.error.main} />
                      </ListItemIcon>
                      <ListItemText primary="Advances" />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        push(appRoutes.admin.taxes);
                        toggleDrawer();
                      }}
                    >
                      <ListItemIcon>
                        <RiHandCoinLine size={24} color={theme.palette.warning.main} />
                      </ListItemIcon>
                      <ListItemText primary="Tax Rates" />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        push(appRoutes.admin.marketingrates);
                        toggleDrawer();
                      }}
                    >
                      <ListItemIcon>
                        <VscDashboard size={24} color={theme.palette.primary.main} />
                      </ListItemIcon>
                      <ListItemText primary="Marketing Rates" />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        push(appRoutes.admin.companySettings());
                        toggleDrawer();
                      }}
                    >
                      <ListItemIcon>
                        <Badge
                          badgeContent={isCompanySettings ? 0 : "❕"}
                          color="error"
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          classes={{ root: classes.customBadge }}
                        >
                          <VscSettings size={24} color={theme.palette.success.main} />
                        </Badge>
                      </ListItemIcon>
                      <ListItemText primary="Company Settings" />
                    </ListItem>
                  </>
                )
              }
            </List>
            <Divider />
          </>
        )}

        {!isLoggedIn && (
          <>
            <List>
              <ListItem button onClick={() => push(appRoutes.auth.login())}>
                <ListItemIcon>
                  <BiLogInCircle size={24} color={theme.palette.primary.main} />
                </ListItemIcon>
                <ListItemText primary="Log in" />
              </ListItem>
            </List>
            <List>
              <ListItem button onClick={() => push(appRoutes.auth.signup)}>
                <ListItemIcon>
                  <FaUserPlus size={24} color={theme.palette.primary.main} />
                </ListItemIcon>
                <ListItemText primary="Register" />
              </ListItem>
            </List>
            <Divider />
          </>
        )}

        {isLoggedIn && (
          <>
            <List>
              <ListItem button onClick={logout}>
                <ListItemIcon>
                  <BiLogOutCircle
                    size={24}
                    color={theme.palette.common.white}
                  />
                </ListItemIcon>
                <ListItemText primary="Log out" />
              </ListItem>
            </List>

            <Divider />
          </>
        )}

        <List>
          <Box display="flex" alignItems="center" flexDirection="column">
            <img src={siteLogoURL} />
            <Box margin={1}>
              <Typography variant="subtitle2">{siteName}</Typography>
            </Box>
          </Box>
        </List>
      </SimpleDrawer>
    </>
  );
}
