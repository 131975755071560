import {useCallback} from 'react';
import {debounce} from '@material-ui/core';

export function useDebounced(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fn: (...args: any) => unknown,
  wait: number,
  deps: unknown[],
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(debounce(fn, wait), deps);
}
