import React, {useCallback} from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Drawer} from '@material-ui/core';

interface SimpleDrawerProps {
  onClose: () => void;
  isOpen: boolean;
  anchor?: 'left' | 'right';
  size?: 'small' | 'medium' | 'large' | 'auto';
  strictClose?: boolean;
  fullMobileWidth?: boolean;
  children: React.ReactNode;
}

const useStyles = makeStyles<Theme, {fullMobileWidth: boolean}>(theme => ({
  small: {
    width: '150px',
    [theme.breakpoints.down('xs')]: {
      width: ({fullMobileWidth}) =>
        fullMobileWidth ? '100%' : 'calc(100% - 60px)',
    },
  },
  medium: {
    width: '250px',
    [theme.breakpoints.down('xs')]: {
      width: ({fullMobileWidth}) =>
        fullMobileWidth ? '100%' : 'calc(100% - 60px)',
    },
  },
  large: {
    width: '450px',
    [theme.breakpoints.down('xs')]: {
      width: ({fullMobileWidth}) =>
        fullMobileWidth ? '100%' : 'calc(100% - 60px)',
    },
  },
  auto: {},
}));

const ON_CLOSE_LIGHT_REASONS = ['backdropClick', 'escapeKeyDown'];

export default function SimpleDrawer({
  onClose,
  isOpen,
  anchor = 'right',
  size = 'medium',
  strictClose = true,
  fullMobileWidth = true,
  children,
}: SimpleDrawerProps) {
  const classes = useStyles({fullMobileWidth});
  const handleClose = useCallback(
    (e, reason: 'backdropClick' | 'escapeKeyDown') => {
      if (strictClose && ON_CLOSE_LIGHT_REASONS.includes(reason)) {
        return;
      }

      onClose();
    },
    [onClose, strictClose],
  );

  return (
    <Drawer
      anchor={anchor}
      open={isOpen}
      onClose={handleClose}
      classes={{paper: classes[size]}}
    >
      {children}
    </Drawer>
  );
}
