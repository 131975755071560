import { Tooltip, Zoom } from '@material-ui/core';
import { styled } from '@material-ui/styles';

const BadgeTooltip = ({ message, direction }: {
  message: string;
  direction: string;
}) => {

  const TooltipInner = styled('div')({
    position: 'absolute',
    top: '14%',
    transform: (direction === 'right') ? 'scale(1) translate( 50%, -50%)' : 'scale(1) translate( -50%, -50%)',
    width: 20,
    height: 20,
    zIndex: 5,
    left: (direction === 'left') ? '14%' : 'auto',
    right: (direction === 'right') ? '14%' : 'auto',
  });

  return (<Tooltip
    title={message}
    TransitionComponent={Zoom}
    TransitionProps={{ timeout: 300 }}
    placement="bottom"
    arrow
  >
    <TooltipInner />
  </Tooltip>)
}

export default BadgeTooltip;
