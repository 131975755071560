import React, { useEffect } from 'react';
import { Badge, } from '@material-ui/core';
import { RiAccountCircleFill } from 'react-icons/ri';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useQuery } from 'react-query';

import BadgeTooltip from '@/common/components/tooltips/BadgeTooltip';
import { queries } from '@/common/api/operations';
import { useUserContext } from '@/common/contexts/UserContext';
import { useApiContext } from '@/common/contexts/ApiContext';

const useStyles = makeStyles<Theme>(theme => ({
  badge: {
    fontSize: 10,
    left: 'calc(50% - 1px)',
    '&.MuiBadge-colorPrimary': {
      background: '#2e7d32',
      color: 'white',
    },
  },
  customBadge: {
    '&.MuiBadge-colorPrimary': {
      background: '#CD8A07',
      color: 'white',
    }
  }
}));

export default function AdminBadge() {
  const { user } = useUserContext();
  const classes = useStyles();
  const userContext = useUserContext();
  const { apiClient } = useApiContext();

  const countMissingElements = user?.countMissingElements ?? 0;
  const isMissingErrors = user?.isMissingErrors;


  const propertytotalQuery = useQuery(
    queries.pendingtotal.get,
    () => apiClient.pendingtotal.get({})
  );

  const pendingResult = propertytotalQuery?.data?.pendingTotal?.[0]?.[0].grand_tot ?? 0;
  const isSubscription = userContext?.user?.isSubscription ?? false;
  const isDelinquent = user?.isDelinquent;

  useEffect(() => {
    propertytotalQuery.refetch();
  }, []);

  const badgeContent = isSubscription ? 'Subscribed' : 'Admin';
  const subscriptionCollor = isDelinquent ? 'error' : 'primary';
  const missingErrorsColor = isMissingErrors ? 'error' : 'primary';

  return (
    <Badge badgeContent={badgeContent} classes={{ badge: classes.badge }} color={isSubscription ? subscriptionCollor : 'secondary'} overlap="circular" anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
      <Badge badgeContent={pendingResult} color="error" overlap="circular" anchorOrigin={{ vertical: "top", horizontal: "left" }}>
        {
          (pendingResult > 0) && <BadgeTooltip message='Admin Errors' direction='left' />
        }

        <Badge
          badgeContent={countMissingElements}
          classes={{ badge: classes.customBadge }}
          color={missingErrorsColor} // error, primary
          overlap="circular"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          {
            (countMissingElements > 0) && <BadgeTooltip message='Rightsmage Errors' direction='right' />
          }
          <RiAccountCircleFill
            size={48}
            color="orange"
          />
        </Badge>
      </Badge>
    </Badge>
  );
};