import React from 'react';
import { useRouter } from 'next/router';
import { RiAccountCircleFill } from 'react-icons/ri';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';

import Badge from '@material-ui/core/Badge';
import { useUserContext } from '@/common/contexts/UserContext';
import { appRoutes, resolveImageUrl } from '@/common/config';

const useStyles = makeStyles<Theme>(theme => ({
  badge: {
    fontSize: 10,
    left: 'calc(50% - 1px)',
    '&.MuiBadge-colorPrimary': {
      background: '#2e7d32',
      color: 'white',
    }
  },
  listingImage: {
    borderRadius: '50%',
  },
  customBadge: {
    '&.MuiBadge-colorPrimary': {
      background: '#CD8A07',
      color: 'white',
    }
  }
}));

export default function UserBadge({ size = '', user, listingId }: {
  user: any;
  listingId: number | null;
  size?: string;
}) {
  const { push } = useRouter();
  const classes = useStyles();
  const userContext = useUserContext();
  const theme = useTheme();

  // Get Users's parameters
  const isSubscription = userContext?.user?.isSubscription ?? false;
  const countMissingElements = userContext?.user?.countMissingElements;
  const isMissingErrors = user?.isMissingErrors;

  // Prepare parameters
  const badgeContent = isSubscription ? 'Subscribed' : null;
  const circleSize = size === 'large' ? 60 : 48;
  const missingErrorsColor = isMissingErrors ? 'error' : 'primary';

  return (
    <Badge
      badgeContent={badgeContent}
      classes={{ badge: classes.badge }}
      color={'primary'}
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    >
      <Badge
        badgeContent={countMissingElements}
        classes={{ badge: classes.customBadge }}
        color={missingErrorsColor} // error, primary
        overlap="circular"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {user?.listingImageId ? (
          <img
            src={resolveImageUrl(user.listingImageId)}
            className={classes.listingImage}
            onClick={() => {
              push(appRoutes.account.listing(listingId ?? 'new'));
            }}
            style={{
              cursor: "pointer",
              width: circleSize,
              height: circleSize,
            }}
          />
        ) : (
          <RiAccountCircleFill
            size={circleSize}
            color={theme.palette.primary.main}
          />
        )}
      </Badge>
    </Badge>
  );
};