import {NextPageContext} from 'next';
import {NextRouter, useRouter} from 'next/router';
import {parse} from 'query-string';

function getQueryParam({asPath, query}: NextRouter, key: string) {
  if (query[key]) {
    return query[key] as string | undefined;
  }

  const parsed = parse(asPath);
  return parsed[key] as string | undefined;
}

function getSlugQueryParam(
  {asPath, query}: NextRouter,
  key: string,
  index = 0,
): string | undefined {
  if (!query[key]) {
    const segments = asPath
      .replace(/[&?].*/, '')
      .split('/')
      .reverse()
      .filter(Boolean);

    return segments[index];
  }

  if (!Array.isArray(query[key])) {
    throw new Error('getSlugQueryParam is for catch all routes');
  }

  const arr = query[key] as string[];
  const param = [...arr].reverse()[index];

  return param;
}

function convertToMaybeNumber(val: string | undefined): number | undefined {
  const parsed = val ? parseInt(val, 10) : undefined;
  return typeof parsed !== 'undefined' && !isNaN(parsed) ? parsed : undefined;
}

function convertToMaybeBoolean(val: string | undefined): boolean | undefined {
  if (val === 'true') {
    return true;
  } else if (val === 'false') {
    return false;
  } else {
    return undefined;
  }
}

function convertToMaybeUserId(val: string | undefined): string | undefined {
  if (!val) {
    return undefined;
  }

  return /[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/.test(
    val,
  )
    ? val
    : undefined;
}

interface QueryParams {
  listingId?: number | 'new';
  myListingId?: number | 'new';
  hashtagId?: number | 'new';
  badgeId?: number | 'new';
  userId?: string | 'new';
  siteSettingsId?: number | 'new';
  badgeRequestId?: number;
  search?: string;
  tabName?: string;
  badgeName?: string;
  categoryName?: string;
  redirectTo?: string;
  twitterHandleStatus?: string;
  listingImageStatus?: string;
  productImageStatus?: string;
  productAudioStatus?: string;
  role?: string;
  status?: string;
  text?: string;
  verificationToken?: string;
  success?: boolean;
  knowledgeBaseCategoryId?: string;
}

export default function useQueryParams(): QueryParams {
  const router = useRouter();
  const search = getQueryParam(router, 'q');
  const categoryName = getQueryParam(router, 'category');
  const badgeName = getQueryParam(router, 'badges');
  const redirectTo = getQueryParam(router, 'redirectTo');
  const tabName = getQueryParam(router, 'tab');
  const myListingId = getQueryParam(router, 'myListingId');
  const twitterHandleStatus = getQueryParam(router, 'twitterHandleStatus');
  const role = getQueryParam(router, 'role');
  const status = getQueryParam(router, 'status');
  const text = getQueryParam(router, 'text');
  const verificationToken = getQueryParam(router, 'verificationToken');
  const success = getQueryParam(router, 'success');
  const listingImageStatus = getQueryParam(router, 'listingImageStatus');
  const productImageStatus = getQueryParam(router, 'productImageStatus');
  const productAudioStatus = getQueryParam(router, 'productAudioStatus');

  const listingId = getSlugQueryParam(router, 'listingId');
  const hashtagId = getSlugQueryParam(router, 'hashtagId');
  const badgeId = getSlugQueryParam(router, 'badgeId');
  const badgeRequestId = getSlugQueryParam(router, 'badgeRequestId');
  const userId = getSlugQueryParam(router, 'userId');
  const siteSettingsId = getSlugQueryParam(router, 'siteSettingsId');
  const knowledgeBaseCategoryId = getQueryParam(router, 'categoryId');

  return {
    tabName,
    search,
    categoryName,
    badgeName,
    redirectTo,
    twitterHandleStatus,
    listingImageStatus,
    productImageStatus,
    productAudioStatus,
    status,
    text,
    role,
    verificationToken,
    success: convertToMaybeBoolean(success),
    myListingId:
      myListingId === 'new' ? 'new' : convertToMaybeNumber(myListingId),
    badgeId: badgeId === 'new' ? 'new' : convertToMaybeNumber(badgeId),
    hashtagId: hashtagId === 'new' ? 'new' : convertToMaybeNumber(hashtagId),
    listingId: listingId === 'new' ? 'new' : convertToMaybeNumber(listingId),
    userId: userId === 'new' ? 'new' : convertToMaybeUserId(userId),
    siteSettingsId:
      siteSettingsId === 'new' ? 'new' : convertToMaybeNumber(siteSettingsId),
    // siteSettingsId: siteSettingsId === 'new' ? 'new' : siteSettingsId,
    badgeRequestId: convertToMaybeNumber(badgeRequestId),
    knowledgeBaseCategoryId,
  };
}

export function ssrQueryParams(ctx: NextPageContext): QueryParams {
  const search = ctx.query.q as string | undefined;
  const categoryName = ctx.query.category as string | undefined;
  const badgeName = ctx.query.badges as string | undefined;
  const redirectTo = ctx.query.redirectTo as string | undefined;
  const tabName = ctx.query.tab as string | undefined;
  const myListingId = ctx.query.myListingId as string | undefined;
  const verificationToken = ctx.query.verificationToken as string | undefined;
  const success = ctx.query.success as string | undefined;
  const role = ctx.query.role as string | undefined;
  const twitterHandleStatus = ctx.query.twitterHandleStatus as
    | string
    | undefined;
  const listingImageStatus = ctx.query.listingImageStatus as string | undefined;
  const productImageStatus = ctx.query.productImageStatus as string | undefined;
  const productAudioStatus = ctx.query.productAudioStatus as string | undefined;
  const status = ctx.query.status as string | undefined;
  const text = ctx.query.text as string | undefined;
  const listingId =
    ctx.query.listingId &&
    ((ctx.query.listingId as string[]).reverse()[0] as string | undefined);
  const hashtagId =
    ctx.query.hashtagId &&
    ((ctx.query.hashtagId as string[]).reverse()[0] as string | undefined);
  const badgeId =
    ctx.query.badgeId &&
    ((ctx.query.badgeId as string[]).reverse()[0] as string | undefined);
  const userId =
    ctx.query.userId &&
    ((ctx.query.userId as string[]).reverse()[0] as string | undefined);
  const badgeRequestId =
    ctx.query.badgeRequestId &&
    ((ctx.query.badgeRequestId as string[]).reverse()[0] as string | undefined);
  const siteSettingsId =
    ctx.query.siteSettingsId &&
    ((ctx.query.siteSettingsId as string[]).reverse()[0] as string | undefined);
  const knowledgeBaseCategoryId = ctx.query.categoryId as string | undefined;

  return {
    role,
    tabName,
    search,
    categoryName,
    badgeName,
    redirectTo,
    twitterHandleStatus,
    listingImageStatus,
    productImageStatus,
    productAudioStatus,
    status,
    text,
    verificationToken,
    success: convertToMaybeBoolean(success),
    myListingId:
      myListingId === 'new' ? 'new' : convertToMaybeNumber(myListingId),
    badgeId: badgeId === 'new' ? 'new' : convertToMaybeNumber(badgeId),
    hashtagId: hashtagId === 'new' ? 'new' : convertToMaybeNumber(hashtagId),
    listingId: listingId === 'new' ? 'new' : convertToMaybeNumber(listingId),
    userId: userId === 'new' ? 'new' : convertToMaybeUserId(userId),
    siteSettingsId: siteSettingsId === 'new' ? 'new' : convertToMaybeNumber(siteSettingsId),
    // siteSettingsId: siteSettingsId === 'new' ? 'new' : siteSettingsId,
    badgeRequestId: convertToMaybeNumber(badgeRequestId),
    knowledgeBaseCategoryId,
  };
}
